import React from 'react'
import styled from 'styled-components'

const ResponsiveTitle = styled.h1`
  font-weight: 600;
  line-height: 1.25;
  margin: 3rem 0 1rem 0;
  text-transform: capitalize;
  font-size: 1.2em;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: none;
    margin: 5rem 0 1rem 0;
  }
`

const PostTitle = props => {
  return <ResponsiveTitle>{props.children}</ResponsiveTitle>
}

export default PostTitle
