import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import ContainerContact from '../components/ContainerContact'
import PageTitle from '../components/PageTitle'
import PageResponsiveTitle from '../components/PageResponsiveTitle'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import styled from 'styled-components'
import PageBody from '../components/PageBody'
import { graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import facebook from '../images/facebook-icon.svg'
import spotify from '../images/spotify-icon.svg'
import instagram from '../images/instagram-icon.svg'

const ContentWrapper = styled.div`
  @media (min-width: ${props => props.theme.responsive.medium}) {
    display: flex;
  }
`

const MainContent = styled.div`
  p{
    font-size: .8em;
  }
  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: .9em;
    margin-top: 50px;
    flex: 0 0 50%;
    padding-right: 1.5em;
    margin-top: 50px;
  }
` 

const SidebarSocial = styled.div`
  padding: 0;
  margin: 20px 0;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    padding: 30px 0;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
  }

  ul {
    list-style: none;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      display: flex;
      justify-content: space-around;   
    }
    margin-bottom: 0;
    li {
      display: inline-block;
      padding-right: 50px;
    }
  }
`

const Brand = styled.img`
  width: 25px;
  max-height: 20px;
`

const Contact = ({ data }) => {
  const postNode = {
    title: `Contact - ${config.siteTitle}`,
  }
  const contactContent = data.allContentfulContact.edges[0].node.contactPage
  return (
    <Layout>
      <Helmet>
        <title>{`Contact - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />
      <ContainerContact>
          <PageTitle>Contact</PageTitle>
          <PageResponsiveTitle>Contact</PageResponsiveTitle>
          <ContentWrapper>
            <MainContent>
              <PageBody body={contactContent} />
              <SidebarSocial>
                <ul>
                  <li>
                    <OutboundLink href="https://www.facebook.com/mauricevandermeijsdotcom/" target="_blank" rel="noopener norefferer">
                      <Brand src={facebook} alt="Facebook" />
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink href="https://open.spotify.com/user/1147824736/playlist/16DgC8UVONqCQmrdYTUTne?si=4IEQb1EkQQGVqeJyVVrwfg" target="_blank" rel="noopener norefferer">
                      <Brand src={spotify} alt="Spotify" />
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink href="https://www.instagram.com/maurice_meijs/" target="_blank" rel="noopener norefferer">
                      <Brand src={instagram} alt="Instagram" />
                    </OutboundLink>
                  </li>
                </ul>
              </SidebarSocial>
              
            </MainContent>
            <ContactForm />
        </ContentWrapper>
      </ContainerContact>
    </Layout>
  )
}

export const query = graphql`
  query{
    allContentfulContact{
      edges{
        node{
        contactPage{
          childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`

export default Contact
