import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  display: none;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: block;
  }
  font-size: ${props => (props.small ? '2em' : '3em')};
  text-transform: capitalize;
  font-weight: 300;
  text-align: center;
  margin: 0 0 3rem 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 5px 0 1.5rem 0;
  }
  margin: ${props => (props.small ? '2rem 0 1rem 0' : '3rem 0 3rem 0')};
  line-height: 1.2;
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
  
`

const PageTitle = props => {
  return <Title small={props.small}>{props.children}</Title>
}

export default PageTitle
